import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector } from "../selector";

export const getrewardData = createAsyncThunk(
  "earn/getrewardData",
  async (payload, { dispatch }) => {
    try {
      let url = payload
        ? `/account/bonus?${buildQueryString(payload)}`
        : "/account/bonus";
      dispatch(startLoading());
      const { data, headers } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, status: true, total: headers?.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const getreferralData = createAsyncThunk(
  "earn/getreferralData",
  async (payload, { dispatch }) => {
    try {
      let url = payload
        ? `/account/rewards/list?${buildQueryString(payload)}`
        : "/account/rewards/list";
      dispatch(startLoading());
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res?.data, status: true, total: res?.headers.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getreferralBlsTotal = createAsyncThunk(
  "earn/getreferralBlsTotal",
  async (payload, { dispatch }) => {
    try {
      let url = "account/currency_based_rewards";
      dispatch(startLoading());
      const data = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { list: data.usdt, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getreferralBlsLeft = createAsyncThunk(
  "earn/getreferralBlsLeft",
  async (payload, { dispatch }) => {
    try {
      let url = "account/redeming/currency_based_rewards";
      dispatch(startLoading());
      const data = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { list: data.usdt, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getreferredMembers = createAsyncThunk(
  "earn/getreferredMembers",
  async (payload, { dispatch }) => {
    try {
      let url = "account/referred_members";
      dispatch(startLoading());
      const data = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { list: data, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const getredeemReward = createAsyncThunk(
  "earn/getredeemReward",
  async (payload, { dispatch }) => {
    try {
      let url = `account/send_rewards?${buildQueryString(payload)}`;
      dispatch(startLoading());
      const data = await API.post(config.peatio)(url);
      dispatch(stopLoading());
      return { list: data, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const getrrewardSettings = createAsyncThunk(
  "earn/getrrewardSettings",
  async (payload, { dispatch }) => {
    try {
      let url = `public/reward_settings`;
      dispatch(startLoading());
      const data = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { list: data, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
