import { createSlice } from "@reduxjs/toolkit";
import { getDeviceData, getLoginActivity, getSecurityActivity } from "../../services/securityServie";

export const securitySlices = createSlice({
    name: "securitySlices",
    initialState: {
        error: false,
        list: [],
        totalDevice: 0,
        activityList: [],
        totalActivity: 0,
        secureActivityList: [],
        totalSecureActivity: 0
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDeviceData.pending, (state) => { })
            .addCase(getDeviceData.fulfilled, (state, action) => {
                state.list = action.payload.list;
                state.totalDevice = action.payload.total;
            })
            .addCase(getDeviceData.rejected, (state) => { })

            .addCase(getLoginActivity.pending, (state) => { })
            .addCase(getLoginActivity.fulfilled, (state, action) => {
                state.activityList = action.payload.list;
                state.totalActivity = action.payload.total;
            })
            .addCase(getLoginActivity.rejected, (state) => { })

            .addCase(getSecurityActivity.pending, (state) => { })
            .addCase(getSecurityActivity.fulfilled, (state, action) => {
                state.secureActivityList = action.payload.list;
                state.totalSecureActivity = action.payload.total;
            })
            .addCase(getSecurityActivity.rejected, (state) => { })
    },
});
export default securitySlices.reducer;
export const { } = securitySlices.actions;
