import React, { useState, useEffect } from 'react';
import './statusDropdown.css';

const StatusDropdown = ({ status, onStatusChange }) => {
    const [selectedStatus, setSelectedStatus] = useState(status || 'Select Status');

    const handleChange = (event) => {
        const newStatus = event.target.value;
        setSelectedStatus(newStatus);
        onStatusChange(newStatus);  // Call the passed-in onStatusChange function
        console.log('Selected Status:', newStatus);
    };

    useEffect(() => {
        if (status !== selectedStatus) {
            setSelectedStatus(status);
        }
    }, [status, selectedStatus]);

    return (
        <div className="dropdown-container">
            <select className="status-dropdown" value={selectedStatus} onChange={handleChange}>
                <option disabled>Select Status</option>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="expired">Expired</option>
            </select>
        </div>
    );
};

export default StatusDropdown;
