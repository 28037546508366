import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { showAlert, startLoading, stopLoading } from "../feature";
import { successToastSelector } from "../selector";
import { getUser } from "./user";

export const fetchLogout = createAsyncThunk(
  "logout/fetchLogout",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/identity/sessions?${buildQueryString(payload)}`;
      await API.delete(config.barong)(url);
      sessionStorage.clear();
      localStorage.removeItem("csrfToken");
      localStorage.removeItem("nationality");
      await dispatch(getUser());
      dispatch(
        successToastSelector({ message: "Logged Out Successfully" }, "success")
      );
      dispatch(stopLoading(false));
      return true;
    } catch (e) {
      dispatch(stopLoading(false));
      return false;
    }
  }
);
