import { createSlice } from "@reduxjs/toolkit";
import { getPlanList,choosePlan } from "../../services/clientlistingServices";

const planListSlice = createSlice({
    name: "plan",
    initialState: {
        error: false,
        loading: false,
        plans: [],
        choosenPlan: null,
        total: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPlanList.pending, (state) => {
                state.error = false;
                state.plans = [];
            })
            .addCase(getPlanList.fulfilled, (state, action) => {
                state.plans = action?.payload?.plans;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getPlanList.rejected, (state) => {
                state.plans = [];
                state.error = true;
            })
            .addCase(choosePlan.fulfilled, (state, action) => {
                state.choosenPlan = action.payload; // Store success data
              })
              .addCase(choosePlan.rejected, (state, action) => {
                state.error = action.payload; // Store error data
              });
            // .addCase(getSingleAgent.pending, (state) => {
            //     state.error = false;
            //     state.loading = true;
            // })
            // .addCase(getSingleAgent.fulfilled, (state, action) => {
            //     state.singleList = action?.payload;
            //     state.loading = false;
            //     state.error = false;
            // })
            // .addCase(getSingleAgent.rejected, (state) => {
            //     state.singleList = {};
            //     state.error = true;
            //     state.loading = false;
            // });
            

    },
});

export const { } = planListSlice.actions;
export default planListSlice.reducer;
