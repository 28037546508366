import { createSlice } from "@reduxjs/toolkit";
import { getAgentList,getSingleAgent } from "../../services/clientlistingServices";

const clientListSlice = createSlice({
    name: "agent",
    initialState: {
        error: false,
        loading: false,
        records: [],
        singleList: {},
        total: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAgentList.pending, (state) => {
                state.error = false;
                state.records = [];
            })
            .addCase(getAgentList.fulfilled, (state, action) => {
                state.records = action?.payload?.records;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getAgentList.rejected, (state) => {
                state.records = [];
                state.error = true;
            })
            .addCase(getSingleAgent.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(getSingleAgent.fulfilled, (state, action) => {
                state.singleList = action?.payload;
                state.loading = false;
                state.error = false;
            })
            .addCase(getSingleAgent.rejected, (state) => {
                state.singleList = {};
                state.error = true;
                state.loading = false;
            });
            

    },
});

export const { } = clientListSlice.actions;
export default clientListSlice.reducer;
