import { lazy } from "react";

export const Mainlayout = lazy(() =>
  import("../Components/Layout/Mainlayout/Mainlayout")
);
export const Dashboard = lazy(() => import("../Components/Pages/Dashboard"));
export const LogoutCompo = lazy(() => import("../Components/Pages/Logout"));
export const PageNotFound = lazy(() =>
  import("../Components/Pages/PageNotFound/PageNotFound")
);
export const Login = lazy(() => import("../Components/Pages/Login"));
export const ChangePassword = lazy(() =>
  import("../Components/Pages/Login/PasswordSetting/ChangePassword")
);
export const ResetPassword = lazy(() =>
  import("../Components/Pages/Login/PasswordSetting/ResetPassword")
);
export const CreateAcount = lazy(() =>
  import("../Components/Pages/SignUp/CreateAcount")
);
export const VerifyEmail = lazy(() =>
  import("../Components/Pages/SignUp/VerifyEmail")
);
export const Verifiaction = lazy(() =>
  import("../Components/Pages/SignUp/Verifiaction")
);
export const VerificationCode = lazy(() =>
  import("../Components/Pages/SignUp/VerificationCode")
);
export const Identity = lazy(() =>
  import("../Components/Pages/SignUp/Identity")
);



export const VerifyIdentity = lazy(() =>
  import("../Components/Pages/SignUp/VerifyIdentity")
);
export const Portfolio = lazy(() =>
  import("../Components/Pages/Portfolio/Portfolio")
);
export const Earn = lazy(() => import("../Components/Pages/Earn"));

export const Client = lazy(() => import("../Components/Pages/Client"));
export const MainDashboard = lazy(() => import("../Components/Pages/MainDashboard"));
export const PDFManiplulation = lazy(() => import("../Components/Pages/Dashboard/pdfList"));



export const EmailConfirmation = lazy(() =>
  import("../Components/Pages/EmailConfirmation/index.jsx")
);


export const Landing = lazy(() =>
  import("../Components/Pages/Landing/index.jsx")
);

export const Agent = lazy(() =>
  import("../Components/Pages/Agent/index.jsx")
);

export const ClientView = lazy(() =>
  import("../Components/Pages/ClientView/index.jsx")
);

export const PricingAgent = lazy(() =>
  import("../Components/Pages/PricingAgent/index.jsx")
);

export const Faq = lazy(() =>
  import("../Components/Pages/Faq/index.jsx")
);

export const Services = lazy(() =>
  import("../Components/Pages/Services/index.jsx")
);

export const TermsConditions = lazy(() =>
  import("../Components/Pages/TermsConditions/index.jsx")
);

export const PrivacyPolicy = lazy(() =>
  import("../Components/Pages/PrivacyPolicy/index.jsx")
);

export const ContactSales = lazy(() =>
  import("../Components/Pages/ContactSales/index.jsx")
);

export const Help = lazy(() =>
  import("../Components/Pages/Help/index.jsx")
);








