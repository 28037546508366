import { createSlice } from "@reduxjs/toolkit";
import {
  corporateStep1DefaultVal,
  corporateStep2DefaultVal,
  retailDefaultVal,
  retailStep2DefaultVal,
  retailStep3DefaultVal,
} from "../../../staticObjects";
import { getProfileData } from "../../services";

const initialState = {
  userData: {},
  isLoading: false,
  typeOfKyc: "",
  kycStepChange: 1,
  kycLocalData: {
    retailKycStep1: retailDefaultVal,
    retailKycStep2: retailStep2DefaultVal,
    retailKycStep3: retailStep3DefaultVal,
    corporateStep1: corporateStep1DefaultVal,
    corporateStep2: corporateStep2DefaultVal,
  },
};

const settingSlice = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: {
    kycLocalDataAction: (state, action) => {
      state.kycLocalData = { ...state.kycLocalData, ...action.payload };
    },
    kycStepUpdete: (state, action) => {
      state.kycStepChange = action.payload;
    },
    setLocalTypeOfKyc: (state, action) => {
      state.typeOfKyc = action.payload;
    },
    resetKycLocalDataAction: (state, action) => {
      state.kycLocalData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfileData.fulfilled, (state, action) => {
        state.userData = { ...action?.payload?.data };
        state.isLoading = false;
      })
      .addCase(getProfileData.rejected, (state) => {
        state.userData = {};
        state.isLoading = false;
      });
  },
});

export const {
  kycLocalDataAction,
  resetKycLocalDataAction,
  setLocalTypeOfKyc,
  kycStepUpdete,
} = settingSlice.actions;

export default settingSlice.reducer;
