import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";

export const getBlogPosts = createAsyncThunk(
    "blogs/getBlogPosts",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            let id = payload.id
            dispatch(startLoading());
            let url = "";
            id ?
                url = `public/blog_posts/${id}`
                : url = `public/blog_posts?${buildQueryString(payload)}`;
            let res = await API.get(config.peatioWithHeader)(url);
            dispatch(stopLoading());
            return { list: res?.data, total: res?.headers?.total, status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);

export const getSinglePost = createAsyncThunk(
    "blogs/getSinglePost",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            dispatch(startLoading());
            let url = "";
            url = `public/blog_posts/${payload?.id}`;
            let res = await API.get(config.peatio)(url);
            dispatch(stopLoading());
            return { data: res, status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e, "error"));
            return rejectWithValue(e);
        }
    }
);



// export const UpdateProfile = createAsyncThunk(
//     "user/Profile",
//     async (payload, { rejectWithValue, dispatch }) => {
//       try {
//         dispatch(startLoading());
//         let url = `/client/account/?${payload.id}`;
//         let res = await API.post(config.barong)(url);
//         dispatch(
//           successToastSelector({
//             message: " Plan Choosen Successfully",
//           })
//         );
//         dispatch(stopLoading());
//         return res;
  
//       } catch (e) {
//         dispatch(stopLoading());
//         dispatch(errorToastSelector(e, "error"));
//         return rejectWithValue(e);
//       }
//     }
//   );