import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, startTableDataLoading, stopLoading, stopTableDataLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { startBurnDataLoading, startRewardDataLoading, stopBurnDataLoading, stopRewardDataLoading } from "../feature/ourToken/ourToken.slice";

export const getTokenDetails = createAsyncThunk(
	"token/getTokenDetails",
	async (payload, { dispatch }) => {
		try {
			dispatch(startLoading());
			let url = `/agent/client`;
			const res = await API.get(config.barong)(url);
			dispatch(stopLoading());
			return res;
		} catch (e) {
			dispatch(stopLoading());
			dispatch(errorToastSelector(e, "error"));
			return false;
		}
	}
);

export const getBurnTokenDetails = createAsyncThunk(
	"token/getBurnTokenDetails",
	async (payload, { dispatch }) => {
		try {
			dispatch(startBurnDataLoading());
			let url = `/public/burn_info?${buildQueryString(payload)}`;
			const res = await API.get(config.peatioWithHeader)(url);
			dispatch(stopBurnDataLoading());
			return res;
		} catch (e) {
			dispatch(stopBurnDataLoading());
			dispatch(errorToastSelector(e, "error"));
			return false;
		}
	}
);

export const getRewardTokenDetails = createAsyncThunk(
	"token/getRewardTokenDetails",
	async (payload, { dispatch }) => {
		try {
			dispatch(startRewardDataLoading());
			let url = `/public/reward_info?${buildQueryString(payload)}`;
			const res = await API.get(config.peatioWithHeader)(url);
			dispatch(stopRewardDataLoading());
			return res;
		} catch (e) {
			dispatch(stopRewardDataLoading());
			dispatch(errorToastSelector(e, "error"));
			return false;
		}
	}
);

