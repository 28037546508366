import { createSlice } from "@reduxjs/toolkit";
import {
  getAllListedCoins,
  getCoinLists,
  getSingleCoinData,
  submitAsset,
  submitCompliance,
  submitListAgreement,
} from "../../services/coinlistingService";

const initialState = {
  step: 0,
  percentage: 0,
  agreementData: {},
  assetData: {},
  complianceData: {},
  success: false,
  error: false,
  loading: false,
  list: [],
  listTotal: [],
  assetObj: null,
  listedCoinList: [],
  listedCoinsTotal: 0,
};

const slice = createSlice({
  name: "coinlisting",
  initialState: initialState,
  reducers: {
    setListingState: (state, action) => {
      state[action?.payload?.key] = action?.payload?.data;
    },
    resetListingState: (state, action) => {
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitListAgreement.fulfilled, (state, action) => {
        let agreementData = {
          title: action?.payload?.data?.title,
          signature: action?.payload?.data?.signature,
          id: action?.payload?.data?.id,
        };
        state.agreementData = agreementData;
        state.percentage = action?.payload?.data?.progress_percentage;
        state.assetObj = action?.payload?.data;
      })
      .addCase(submitAsset.fulfilled, (state, action) => {
        let assetData = action?.payload?.data?.asset;
        state.assetData = assetData;
        state.percentage = action?.payload?.data?.progress_percentage;
        state.assetObj = action?.payload?.data;
      })
      .addCase(submitCompliance.fulfilled, (state, action) => {
        let complianceData = action?.payload?.data?.compliance;
        state.complianceData = complianceData;
        state.percentage = action?.payload?.data?.progress_percentage;
        state.assetObj = action?.payload?.data;
      })
      .addCase(getCoinLists.fulfilled, (state, action) => {
        state.list = action?.payload?.list;
        state.listTotal = action?.payload?.total ?? 0;
      })
      .addCase(getSingleCoinData.fulfilled, (state, action) => {
        state.assetObj = action?.payload?.data;
      })
      .addCase(getAllListedCoins.fulfilled, (state, action) => {
        state.listedCoinList = action?.payload?.list;
        state.listedCoinsTotal = action?.payload?.total ?? 0;
      });
  },
});

export const { setListingState, resetListingState } = slice.actions;

export default slice.reducer;
