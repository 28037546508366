import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { notiOpt } from "../../staticObjects";

export const getNotification = createAsyncThunk(
  "notification/getNotification",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/notifications?${buildQueryString(payload)}`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);

export const forReadDelete = createAsyncThunk(
  "notification/forReadDelete",
  async (payload, { dispatch }) => {
    const actType = payload.actionType;
    delete payload.actionType;
    try {
      actType !== "readOne" && dispatch(startLoading());
      let url = `/account/notifications/action?${buildQueryString(payload)}`;
      let res = await API.put(config.peatio)(url);
      dispatch(stopLoading());
      return { status: true, res, actionType: actType };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return { status: false };
    }
  }
);

export const getAnnouncement = createAsyncThunk(
  "notification/getAnnouncement",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/admin/notifications?${buildQueryString(payload)}`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);
