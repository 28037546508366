import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    message: '',
    type: "",
    code: "",
    lan: ""
  },
  reducers: {
    showAlert: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.code = action.payload.code;
    },
    getSelectedLan: (state, action) => {
      state.lan = action?.payload;
    },
  }
});
export default alertSlice.reducer
export const { showAlert, getSelectedLan } = alertSlice.actions;