import { createSlice } from "@reduxjs/toolkit";
import {
  forReadDelete,
  getAnnouncement,
  getNotification,
} from "../../services/notificationServices";
import { notiOpt } from "../../../staticObjects";
export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notiTotal: 0,
    notiList: [],
    notiUnreadCount: 0,
    announceTotal: 0,
    announceList: [],
  },
  reducers: {
    notiListBySocket: (state, action) => {
      state.notiList = [action.payload?.data, ...state.notiList];
      state.notiUnreadCount += 1;
    },
    announceListBySocket: (state, action) => {
      state.announceList = [action.payload?.data, ...state.announceList];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {})
      .addCase(getNotification.fulfilled, (state, action) => {
        state.notiList = action.payload?.data?.list;
        state.notiUnreadCount = action.payload?.data?.unread_count;
        state.notiTotal = action?.payload?.headers.total || 0;
      })
      .addCase(getNotification.rejected, (state) => {})

      .addCase(forReadDelete.pending, (state) => {})
      .addCase(forReadDelete.fulfilled, (state, action) => {
        if (action.payload.actionType === notiOpt.readOne) {
          if (state.notiUnreadCount > 0) {
            state.notiUnreadCount -= 1;
          }
        }
      })
      .addCase(forReadDelete.rejected, (state) => {})

      .addCase(getAnnouncement.pending, (state) => {})
      .addCase(getAnnouncement.fulfilled, (state, action) => {
        state.announceList = action.payload?.data;
        state.announceTotal = action?.payload?.headers.total || 0;
      })
      .addCase(getAnnouncement.rejected, (state) => {});
  },
});
export default notificationSlice.reducer;
export const { notiListBySocket, announceListBySocket } =
  notificationSlice.actions;
