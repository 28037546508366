import { createSlice } from "@reduxjs/toolkit";
import {
  getBurnTokenDetails,
  getRewardTokenDetails,
  getTokenDetails,
} from "../../services/ourTokenService";

export const ourTokenSlice = createSlice({
  name: "token",
  initialState: {
    total: 0,
    list: [],
    totalRewardCount: 0,
    reward: [],
    totalBurnCount: 0,
    burn: [],
    rewardTableDataLoading:false,
    burnTableDataLoading:false
  },
  reducers: {
    startRewardDataLoading: (state, action) => {
      state.rewardTableDataLoading = true;
    },
    stopRewardDataLoading: (state, action) => {
      state.rewardTableDataLoading = false;
    },
    startBurnDataLoading: (state, action) => {
      state.burnTableDataLoading = true;
    },
    stopBurnDataLoading: (state, action) => {
      state.burnTableDataLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTokenDetails.pending, (state) => {})
      .addCase(getTokenDetails.fulfilled, (state, action) => {
        state.list = action.payload?.data;
        state.total = action?.payload?.headers.total || 0;
      })
      .addCase(getTokenDetails.rejected, (state) => {})

      .addCase(getRewardTokenDetails.pending, (state) => {})
      .addCase(getRewardTokenDetails.fulfilled, (state, action) => {
        state.reward = action.payload?.data;
        state.totalRewardCount = action?.payload?.headers.total || 0;
      })
      .addCase(getRewardTokenDetails.rejected, (state) => {})

      .addCase(getBurnTokenDetails.pending, (state) => {})
      .addCase(getBurnTokenDetails.fulfilled, (state, action) => {
        state.burn = action.payload?.data;
        state.totalBurnCount = action?.payload?.headers.total || 0;
      })
      .addCase(getBurnTokenDetails.rejected, (state) => {});
  },
});
export default ourTokenSlice.reducer;
export const {startRewardDataLoading,stopRewardDataLoading,startBurnDataLoading,stopBurnDataLoading} = ourTokenSlice.actions;
